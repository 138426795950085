<template>
	<v-main class="student-default-view-main primary-background">
		<router-view :key="$route.path" />
	</v-main>
</template>

<script>
export default {
	name: "StudentDefaultView"
};
</script>
